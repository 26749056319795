@font-face {
    font-family: 'UKNumberPlate';
    src: url('./webfonts/UKNumberPlate/UKNumberPlate.woff2') format('woff2'),
    url('./webfonts/UKNumberPlate/UKNumberPlate.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('./webfonts/MaterialIcons/MaterialIcons-Regular.eot'); /* For IE6-8 */
    src: url('./webfonts/MaterialIcons/MaterialIcons-Regular.woff2') format('woff2'),
    url('./webfonts/MaterialIcons/MaterialIcons-Regular.woff') format('woff'),
    url('./webfonts/MaterialIcons/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Socicon';
    src:  url('./webfonts/Socicon/Socicon.eot');
    src:  url('./webfonts/Socicon/Socicon.eot') format('embedded-opentype'),
    url('./webfonts/Socicon/Socicon.woff2?484r1f') format('woff2'),
    url('./webfonts/Socicon/Socicon.ttf?484r1f') format('truetype'),
    url('./webfonts/Socicon/Socicon.woff?484r1f') format('woff'),
    url('./webfonts/Socicon/Socicon.svg?484r1f#Socicon') format('svg');
    font-weight: normal;
    font-style: normal;
}
